<script>
import { quillEditor } from "vue-quill-editor";
import axios from "axios";

export default {
  components: {
    quillEditor,
  },
  props: {
    mode: {
      type: String,
      default: "create", // Puede ser "create" o "edit"
    },
    slug: {
      type: String,
      default: null, // Slug de la página para el modo edición
    },
    allowedSections: {
      type: Array,
      default: () => ["header", "text", "gallery"],
    },
  },
  data() {
    return {
      localeSlug: "",
      sections: [],
      selectedSection: null,
      quillContent: "",
      snowOption: {
        theme: "snow",
      },
      loading: false,
      fetchPageLoading: false,
      fetchPageError: "",
    };
  },
  created() {
    if (this.mode === "edit") {
      this.fetchPage();
    } else {
      this.localeSlug = "Nueva Página";
    }
  },
  methods: {
    async fetchPage() {
      try {
        this.fetchPageLoading = true;
        const response = await axios.get(`/api/pages/${this.slug}`);
        this.localeSlug = response.data.slug;
        this.sections = response.data.sections;
      } catch (error) {
        this.$toast.error("Error al cargar la página.");
        this.fetchPageError = "Error to load the page";
      } finally {
        this.fetchPageLoading = false;
      }
    },

    addSection(type) {
      this.sections.push({ type, content: "" });
      this.$toast.success(`Sección ${type} añadida.`);
    },

    selectSection(index) {
      this.selectedSection = index;
      this.quillContent = this.sections[index]?.content || "";
    },

    saveSectionContent() {
      if (this.selectedSection !== null) {
        this.sections[this.selectedSection].content = this.quillContent;
        this.$toast.success("Sección guardada.");
        this.selectedSection = null;
      }
    },

    deleteSection(index) {
      this.sections.splice(index, 1);
      this.selectedSection = null;
      this.quillContent = "";
      this.$toast.success("Sección eliminada.");
    },

    async savePage() {
      this.loading = true;
      const endpoint =
        this.mode === "edit"
          ? `/api/pages/edit/${this.slug}`
          : `/api/pages/create`;
      try {
        await axios.post(endpoint, {
          slug: this.title,
          sections: this.sections,
        });
        this.$toast.success("Página guardada con éxito.");
        this.$router.push({ name: "PageManager" });
      } catch (error) {
        console.log(error);
        this.$toast.error("Error al guardar la página.");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <div v-if="fetchPageLoading">
    <b-spinner small></b-spinner>
  </div>

  <div v-else-if="!fetchPageLoading && fetchPageError">
    {{ fetchPageError }}
  </div>

  <div v-else class="container">
    <!-- Slug -->
    <b-form-group label="Slug de la página">
      <b-form-input
        v-model="localeSlug"
        placeholder="Ingrese el slug"
      ></b-form-input>
    </b-form-group>

    <!-- Add section button -->
    <b-dropdown text="Añadir Sección" variant="primary" class="mb-3">
      <b-dropdown-item
        v-for="type in allowedSections"
        :key="type"
        @click="addSection(type)"
      >
        {{ type.toUpperCase() }}
      </b-dropdown-item>
    </b-dropdown>

    <!-- Section list -->
    <b-card>
      <b-card-header>
        <h5>Secciones</h5>
      </b-card-header>
      <b-card-body>
        <b-list-group flush>
          <b-list-group-item
            v-for="(section, index) in sections"
            :key="index"
            @click="selectSection(index)"
            :class="{ active: index === selectedSection }"
            class="cursor-pointer"
            action
          >
            {{ section.type.toUpperCase() }}
            <b-button
              variant="danger"
              size="sm"
              class="float-right"
              @click.stop="deleteSection(index)"
            >
              Eliminar
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </b-card-body>
    </b-card>

    <!-- Edit content -->
    <div v-if="selectedSection !== null" class="mt-4">
      <b-card>
        <b-card-header>
          <h5>Editor de Contenido</h5>
        </b-card-header>
        <b-card-body>
          <quill-editor v-model="quillContent" :options="snowOption" />
        </b-card-body>
        <b-card-footer>
          <b-button variant="primary" @click="saveSectionContent">
            Guardar Sección
          </b-button>
        </b-card-footer>
      </b-card>
    </div>

    <!-- Save page button -->
    <div class="mt-4">
      <b-button variant="success" @click="savePage" :disabled="loading">
        {{ mode === "edit" ? "Actualizar Página" : "Crear Página" }}
      </b-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@core/scss/vue/libs/quill.scss";

.active {
  background-color: #007bff;
  color: white;
}
</style>
