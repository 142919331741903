<template>
  <div class="pages-manager">
    <div class="mb-3">
      <b-button variant="primary" @click="handleAction('create')"
        >Añadir Nueva Página</b-button
      >
    </div>

    <b-card>
      <b-card-body>
        <b-list-group flush>
          <b-list-group-item
            v-for="(page, index) in pages"
            :key="index"
            class="cursor-pointer"
            action
          >
            {{ page.title }}

            <b-button
              class="float-right"
              variant="warning"
              size="sm"
              @click.stop="editPage(page)"
            >
              Editar
            </b-button>
            
            <b-button
              class="float-right"
              variant="danger"
              size="sm"
              @click.stop="deletePage(page.id)"
            >
              Eliminar
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </b-card-body>
    </b-card>
    
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pages: [],
      editorMode: "create",
    };
  },
  methods: {
    async fetchPages() {
      try {
        const response = await axios.get("/api/pages");
        this.pages = response.data;
      } catch (error) {
        this.$toast.error("Error al cargar las páginas.");
      } finally {
        this.pages = [
          {
            slug: "firstPage",
            title: "Mi primera página",
            sections: [
              {
                type: "header",
                content: "Bienvenido a mi página",
              },
              {
                type: "gallery",
                images: ["url1.jpg", "url2.jpg"],
              },
              {
                type: "text",
                content: "Este es un texto de ejemplo.",
              },
            ],
          },
        ];
      }
    },
    
    handleAction(mode, data = null) {
      const slug = mode === "create" ? "" : data.title;
      this.$router.push({
        name: "PageEditor",
        params: { mode, slug },
      });
    },

    editPage(page) {
      this.handleAction("edit", page);
    },

    async deletePage(pageId) {
      try {
        await axios.delete(`/api/pages/${pageId}`);
        this.$toast.success("Página eliminada con éxito.");
        await this.fetchPages();
      } catch (error) {
        this.$toast.error("Error al eliminar la página.");
      }
    },

  },
  mounted() {
    this.fetchPages();
  },
};
</script>

<style scoped>
.pages-manager {
  padding: 1rem;
}
</style>
