import axios from "@/libs/axios";

export default {

    getGamePerformances(context, filter) {

        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${process.env.VUE_APP_URL}/transaction/report-game`, {params: filter}
                )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getGamesLobby(context, filter, payload) {

        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${process.env.VUE_APP_URL}/getGamesLobby`, payload ,{params: filter}
                )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getGamesByWhitelabel(context, {filter, payload}) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${process.env.VUE_APP_URL}/getGamesByWhitelabel`, payload ,{params: filter}
                )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    fetchProductsByWhitelabel(ctx, whitelabelId) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.VUE_APP_URL}/available-products/getBrandsIdsWhitelabels/${whitelabelId}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    },


}
